const module = angular.module('fileUpload', ['angularFileUpload', 'ui.bootstrap']);

module
    .controller('FileUploadCtrl', function(
        $scope,
        $timeout,
        $filter,
        $uibModal,
        $anchorScroll,
        $cookies,
        Imports,
        Message,
        LanguageService,
        CompanyService,
        FileUploader,
        ReportService,
        CalendarService
    ) {
        const MAX_FILE_SIZE = 10000;
        $scope.fileType = '';
        $scope.imports = [];
        const employmentInfoColumns = [
            'fornamn',
            'efternamn',
            'anstallningsform',
            'personnummer',
            'anstallningsnummer',
            'epost',
            'telefon',
            'pensionsgrundande-arslon',
            'kostnadsstalle',
            'startdatum',
            'slutdatum',
            'note'
        ];
        
        const salaryInfoColumns = [
            'personnummer',
            'pensionsgrundande-arslon',
            'lonedatum',
            'note'
        ];
        $scope.fileInfoColumns = [];

        CompanyService.companiesNotEmptyPromise().then(function (companies) {
            $scope.companies = companies;
            $scope.selectedCompany = CompanyService.selectedCompany;
        }).catch(function (error) {
            $scope.companies = [];
            $scope.selectedCompany = undefined;
        });
        $scope.submitted = false;
        $scope.errorMessage = '';
        $scope.message = '';
        $scope.uploadSuccess = false;
        $scope.xlsxErrors= [];

        $scope.model = {
            selectedCompanies: [],
            form: {},
        };

        $scope.command = {
            includeItp1: false,
            itp2SalaryThreshold: 0,
            orgNoFilter: []
        };

        function formatTodayDate() {
            return CalendarService.formatDayDate(new Date());
        }

        const getUploaderUrl = function (fileType) {
            const language = LanguageService.data.currentLanguage.toUpperCase();
            switch(fileType) {
                case 'collectum':
                    return './api/collectum';
                case 'salary-change':
                    return `./api/files/companies/${$scope.selectedCompany.anstallningsgruppExterntId}/xlsx/salaries?language=${language}`;
                case 'new-employment':
                    return `./api/files/companies/${$scope.selectedCompany.anstallningsgruppExterntId}/xlsx/employments?language=${language}`;
                default:
                    return '';
            }
        };

        $scope.uploader = new FileUploader({
            url: `./api/collectum`,
            headers: {
                'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
            },
            removeAfterUpload: true
        });

        const setFileInfoColumns = (fileType) => {
            switch(fileType) {
                case 'new-employment':
                    $scope.fileInfoColumns = employmentInfoColumns;
                    break;
                case 'salary-change':
                    $scope.fileInfoColumns = salaryInfoColumns;
                    break;
                default:
                    $scope.fileInfoColumns = [];
                    break;
            }
        }

        $scope.$watch('fileType', function(newVal, oldVal) {
            if(newVal !== oldVal) { // Need this check to avoid double fetch at page load
                getImports();
                $scope.submitted = false;
                $scope.errors = [];
                $scope.errorMessage = '';
                $scope.message = '';
                $scope.uploadSuccess = false;
                $scope.uploader.clearQueue();
                $scope.uploader.url = getUploaderUrl(newVal);
                setFileInfoColumns(newVal);
            }
        });

        $scope.uploader.filters = [
            {
                name: 'fileType',
                fn: function(item) {
                    var fileType = item.name;
                    fileType = fileType.slice(fileType.lastIndexOf('.') + 1).toLowerCase();
                    return (fileType === 'xml' && $scope.fileType === 'collectum') ||
                        (fileType === 'xlsx' && ($scope.fileType === 'salary-change' || $scope.fileType === 'new-employment'));
                },
            },
            {
                name: 'fileSize',
                fn: function(item) {
                    return item.size / 1024 < MAX_FILE_SIZE; //Not larger than 10000Kb
                },
            },
            {
                name: 'uniqueFileName',
                fn: function(item) {
                    return !_.findWhere($scope.imports, { filnamn: item.name });
                },
            }
        ];

        var errorMessages = {
            fileType: $filter('translate')('invalid_file_format'),
            uniqueFileName: $filter('translate')('file_previously_imported'),
            fileSize: $filter('translate')('file_to_large'),
        };

        const ensureOnlyOneFileInQueue = function() {
            return $scope.uploader.queue.length !== 1 && $scope.uploader.queue.shift();
        };

        const onBeforeCollectumUpload = function (item) {
            $scope.command.orgNoFilter = _.pluck(
                $scope.model.selectedCompanies,
                'organizationNumber'
            );
            if (!$scope.command.itp2SalaryThreshold) {
                $scope.command.itp2SalaryThreshold = 0;
            }
            item.formData.push($scope.command);
        };

        $scope.uploader.onAfterAddingFile = function (item) {
            ensureOnlyOneFileInQueue();
            $scope.errorMessage = '';
            $scope.message = '';
            item.onBeforeUpload = function() {
                $scope.uploader.url = getUploaderUrl($scope.fileType);
                switch($scope.fileType) {
                    case 'collectum':
                        onBeforeCollectumUpload(item);
                        break;
                    default:
                        break;
                }
            };
        };

        $scope.uploader.onWhenAddingFileFailed = function (item, filter) {
            $scope.submitted = false;
            $scope.errorMessage = errorMessages[filter.name];
        };

        $scope.uploader.onCompleteItem = function() {
            $scope.submitted = true;
        };

        $scope.uploader.onErrorItem = function(item, response, status, headers) {
            $scope.uploadSuccess = false;
            item.isSuccess = false;
            item.isError = true;
            switch (status) {
                case 401:
                case 403:
                    $scope.message = $filter('translate')('no_permission_detailed');
                    break;
                default:
                    $scope.message = $filter('translate')('Error_message') + ': ' + (response.message || 'status:' + status);
            }
        };

        $scope.uploader.onSuccessItem = function(item, response) {
            $scope.uploadSuccess = response.success;
            $scope.result = response;
            if ($scope.uploadSuccess) {
                $scope.uploader.clearQueue();
                getImports();
            } else {
                if(($scope.fileType === 'salary-change' || $scope.fileType === 'new-employment') && response.errors !== undefined) {
                    $scope.errors = response.errors;
                }
                item.isSuccess = false;
                item.isError = true;
                $scope.message = $filter('translate')(response.message);
            }
        };

        function getImportsType() {
            switch($scope.fileType) {
                case 'collectum':
                    return 'collectum';
                case 'salary-change':
                case 'new-employment':
                    return 'csFil';
                default:
                    return '';
            }
        }

        function setLastOngoingNow() {
            $scope.lastOngoingAt = new Date().getTime();
        }

        function getImportsForToday() {
            // Get recent updates without replacing the current result list.
            Imports.history({ from: formatTodayDate(), type: getImportsType() }, function(updatedImports) {
                // Update current list of imports with updated imports(not all)
                // This solution does not replace the Resource array instance $scope.imports containing a $promise and $resolved
                _.each(updatedImports.reverse(), function(updated) {
                    var toUpdate = _.find($scope.imports, function(current) {
                        return current.uuid === updated.uuid;
                    });
                    if (toUpdate) {
                        angular.extend(toUpdate, updated);
                    } else {
                        $scope.imports.unshift(updated); // insert at start
                    }
                });

                pollIfRecentOngoing(updatedImports);
            });
        }

        function pollIfRecentOngoing(imports) {
            var anyOngoing = _.any(imports, function(i) {
                return i.status === 'pagaende';
            });
            if (anyOngoing) {
                setLastOngoingNow();
            }
            // Poll while there has been any ongoing import since last minute
            if ($scope.lastOngoingAt && new Date().getTime() - $scope.lastOngoingAt < 60000) {
                $scope.pendingPollPromise = $timeout(getImportsForToday, 10000);
            } else {
                $scope.pendingPollPromise = undefined;
            }
        }

        function getImports() {
            $scope.imports = Imports.history({type: getImportsType()}, function(imports) {
                pollIfRecentOngoing(imports);
            });
        }

        $scope.$on('$destroy', function onDestroy() {
            if ($scope.pendingPollPromise) {
                $timeout.cancel($scope.pendingPollPromise);
            }
        });

        $scope.downloadTemplate = function () {
            let form;
            const language = LanguageService.data.currentLanguage.toUpperCase();
            switch($scope.fileType) {
                case 'salary-change':
                    form = document.salaryTemplateForm;
                    form.action = `./api/files/xlsx/salaries/template?type=SALARY&language=${language}`;
                    break;
                case 'new-employment':
                    form = document.employmentTemplateForm;
                    form.action = `./api/files/xlsx/salaries/template?type=EMPLOYMENT&language=${language}`;
                    break;
                default:
                    break;
            }
            form.submit();
        };

    });
