angular.module('app')
    .directive('fileErrors', () => ({
        scope: {
            errors: "=",
            file: "="
        },
        template:'<div><div class="row"><div class="col-xs-offset-10 col-xs-2 text-right"><a href ng-if="listLimit" ng-click="showAll()">+ {{\'Show all\' | translate}}</a> <a href ng-if="!listLimit" ng-click="showLess()">- {{\'Show less\' | translate}}</a></div></div><table class="table"><thead><tr><th>{{ \'SALARY_FILE_ROW_NUMBER\' | translate }}</th><th>{{ \'SALARY_FILE_COLUMN_NAME\' | translate }}</th><th>{{ \'SALARY_FILE_ERROR_MESSAGE\' | translate }}</th></tr></thead><tr ng-repeat="error in errors | limitTo:listLimit"><td>{{ error.rowNumber + 1 }}</td><td>{{ error.columnName }}</td><td>{{ getErrorMessageKey(error.columnName, error.errorType) | translate }}</td></tr></table><div class="row"><div class="col-xs-offset-10 col-xs-2 text-right"><a href ng-if="!listLimit" ng-click="showLess()">- {{\'Show less\' | translate}}</a></div></div></div>',
        restrict: "E",
        link: function(scope) {
            scope.showAll = function() {
                scope.listLimit = undefined; // unlimited
            };

            scope.showLess = function() {
                scope.listLimit = 10;
            };

            scope.getErrorMessageKey = function(columnName, errorType) {
                const filteredColumnName = columnName.toLowerCase()
                    .replaceAll('å', 'a')
                    .replaceAll('ä', 'a')
                    .replaceAll('ö', 'o')
                    .replaceAll(' ', '_');

                return scope.file.replaceAll('-', '_') + '_' + filteredColumnName + '_' + errorType.toLowerCase();
            };
            scope.listLimit = 10;
        }
    }));
